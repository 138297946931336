import * as yup from "yup";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  addLocation,
  createLocationSharedUser,
  deleteLocation,
  updateLocation,
  updateLocationSharedUser,
  updateLocationUsers,
} from "../Managers/LocationService";
import { CountrySelect, FormFieldCheckbox, FormFieldText } from "../Components";
import { ICountry, isLocalWAM } from "../Managers";
import { Timezone } from "../Managers/timezone.const";
import { ILocation, ILocationDetail, IUser } from "../Managers/Types";
import { useUsers } from "../Managers/UsersService";
import { useCountries } from "../Managers/API";
import { refreshAppState, showAppModal, showSnackbar } from "../AppState";
import { ConfirmModal } from "./index";
import { Modal } from "../Components/Modal";
import { useTranslation } from "react-i18next";
import { ValidationMessages } from "../Enums";
import { PaginationButtons } from "../Components/PaginationButtons";
import { ManageLocationsModal } from "./ManageLocationsModal";

interface ILocationFormProps
  extends Omit<
    ILocationDetail,
    | "address"
    | "country"
    | "shared_user_id"
    | "sharedUserId"
    | "createdAt"
    | "updatedAt"
    | "AccountId"
    | "lowBatteryCount"
    | "Gateways"
    | "zip"
  > {
  street1: string;
  street2: string;
  country: ICountry;
  zip: string;
}

export const AddEditLocationModal: React.FC<{ loc?: ILocation | null }> = observer(({ loc }) => {
  const countryList = useCountries();
  const countries = countryList.data ?? [];

  const [mode, setMode] = useState<"editLocation" | "createLocation">(loc ? "editLocation" : "createLocation");
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(loc || null);
  const [isSaving, setIsSaving] = useState(false);
  const [allocatedUsers, setAllocatedUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [paginatedFilteredUsers, setPaginatedFilteredUsers] = useState<IUser[]>([]);
  const [country, setCountry] = useState<ICountry>(countries.find((c) => c.code === "US")!);
  const [searchUserString, setSearchUserString] = useState("");

  const users = useUsers();

  const { t } = useTranslation(["locations", "common"]);

  const numberOfUsersPerPaginatedPage = 8;

  useEffect(() => {
    let country = countries.find((c) => c.code === "US")!;
    setCountry(country);
  }, [countries]);

  const validationSchema = yup.object({
    city: yup.string(),
    country: yup.object(),
    name: yup.string().required(t(ValidationMessages.REQUIRED)),
    phone: yup.string().matches(/^[0-9]+$/, t(ValidationMessages.PHONE)),
    sharedUserName: yup.string().min(1),
    sharedUserPassword: yup.string().min(8),
    street1: yup.string().matches(/[^,]+$/, t("locations:wrong_address")),
    street2: yup.string().matches(/[^,]+$/, t("locations:wrong_address")),
    state: yup.string(),
    timezone: yup.number(),
    Users: yup.array(),
    zip: yup.string(),
  });

  useEffect(() => {
    if (users.data) {
      const filtered = [...users.data].sort((a, b) => {
        let nameA = a.first_name + a.last_name;
        let nameB = b.first_name + b.last_name;

        return nameA > nameB ? 1 : -1;
      });

      const searchFiltered = filtered.filter(
        (user) =>
          user.first_name?.toLowerCase().includes(searchUserString.toLowerCase()) ||
          user.last_name?.toLowerCase().includes(searchUserString.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchUserString.toLowerCase()),
      );

      setFilteredUsers(searchFiltered);
    }
  }, [users.data, searchUserString]);

  const showDeleteConfirm = () => {
    // TODO: Make sure we can "flip" like this without losing our context
    const locationToDelete = selectedLocation;
    showAppModal(
      <ConfirmModal
        header={t("locations:delete_location")}
        confirmText={t("locations:delete_location")}
        children={
          <>
            <p>{t("locations:delete_confirmation")}</p> <p>{t("locations:delete_connected_gateway_warning")}</p>
          </>
        }
        onConfirm={() => {
          if (locationToDelete == null) return;
          console.log("Deleting location");
          deleteLocation(locationToDelete)
            .then((r) => {
              console.log("Delete result", r);
              showAppModal(null);
              showSnackbar(t("locations:delete_success"), "success");
              return refreshAppState();
            })
            .catch((e) => {
              const errorMessage = t("locations:delete_error");
              console.log(errorMessage, e);
              showSnackbar(errorMessage, "error");
              showAppModal(<AddEditLocationModal loc={selectedLocation} />);
            });
        }}
      />,
    );
  };

  const composeValues = (values: ILocationFormProps) => {
    let address = values.street1;

    if (values.street2 && values.street2.length > 0) {
      address += ", " + values.street2;
    }

    return {
      ...selectedLocation,
      ...values,
      address,
      country: values.country.code,
      zip: values.zip,
    } as ILocationDetail;
  };

  const createLocation = (values: ILocationFormProps) => {
    setIsSaving(true);

    return addLocation(composeValues(values))
      .then((r) => {
        console.log("Add result", r);
        setSelectedLocation(r);
        showSnackbar(t("locations:add_location_success"), "success");
        return _updateLocationSharedUser(values, r);
      })
      .catch((e) => {
        console.log("Add error", e);
        showSnackbar(t("locations:add_location_error"), "error");
      })
      .finally(() => setIsSaving(false));
  };

  const editLocation = (values: ILocationFormProps) => {
    setIsSaving(true);
    if (mode === "editLocation" && selectedLocation) {
      _updateLocation(composeValues(values), selectedLocation);
    } else if (mode === "createLocation") {
      createLocation(values).then((r) => {
        return refreshAppState();
      });
    }
    showAppModal(null);
  };

  const adjustUsersList = (checked: boolean, user: IUser) => {
    let newAllocatedUsers = [...(allocatedUsers || [])];
    if (checked) {
      newAllocatedUsers.push(user);
    } else {
      newAllocatedUsers = newAllocatedUsers.filter((u) => u._id !== user._id);
    }

    setAllocatedUsers(newAllocatedUsers);
    return newAllocatedUsers;
  };

  const isUserAllocated = (user: IUser) => allocatedUsers.findIndex((u) => u._id === user._id) > -1;

  const _handleSharedUserError = (error: any) => {
    let msg;

    if (error.url && error.url.indexOf("shared_user") > -1) {
      if (typeof error._body === "string") {
        msg = error._body.replace("email address", "shared username");
      }

      if (msg) {
        setIsSaving(false);
        showSnackbar(msg, "error");
      }
    }
  };

  const _updateLocationSharedUser = (values: any, location: ILocation) => {
    if (!isLocalWAM()) {
      return location;
    }

    if (!location.sharedUserId) {
      return createLocationSharedUser(location, {
        identifier: values.sharedUserName,
        password: values.sharedUserPassword,
      } as any);
    } else {
      return updateLocationSharedUser(location, {
        email: values.sharedUserName,
        password: values.sharedUserPassword,
      } as any);
    }
  };

  const _updateLocation = (values: ILocationDetail, location: ILocation) => {
    Promise.all([updateLocation(values), updateLocationUsers(values), _updateLocationSharedUser(values, location)])
      .then((r) => {
        console.log("Update result", r);
        setIsSaving(false);
        showSnackbar(t("locations:update_location_success", { name: location.name }), "success");
      })
      .catch((e) => {
        console.log(e);
        setIsSaving(false);
        showSnackbar(t("locations:update_location_error", { name: location.name }), "error");
        _handleSharedUserError(e);
      });
  };

  const changeAllocatedUsersPagination = (page: number) =>
    // page indexed from 1
    setPaginatedFilteredUsers(
      filteredUsers.slice((page - 1) * numberOfUsersPerPaginatedPage, Math.min(page * numberOfUsersPerPaginatedPage, filteredUsers.length)),
    );

  useEffect(() => {
    setPaginatedFilteredUsers(filteredUsers.slice(0, numberOfUsersPerPaginatedPage) ?? []);
  }, [filteredUsers, searchUserString]);

  const address = (selectedLocation?.address || ",").split(",");
  const street1 = address[0];
  const street2 = address[1];

  let sharedUserName = "";
  if (isLocalWAM() && selectedLocation?.sharedUserId) {
    sharedUserName = selectedLocation?.Users?.find((user) => user._id === selectedLocation?.sharedUserId)?.email || "";
  }

  const initialValues = {
    name: selectedLocation?.name || "",
    street1,
    street2,
    city: selectedLocation?.city || "",
    country: country,
    state: selectedLocation?.state || "",
    zip: selectedLocation?.zip?.toString() ?? "",
    phone: selectedLocation?.phone || "",
    timezone:
      selectedLocation?.timezone ??
      Timezone.find((t) => t.utc.includes(Intl.DateTimeFormat().resolvedOptions().timeZone))?.offset.toString() ??
      "",
    // TODO: It looks like in some calls users comes back as an array of strings?
    Users: selectedLocation?.Users || [],
    sharedUserName,
    sharedUserPassword: selectedLocation?.sharedUserPassword || "",
  } as ILocationFormProps;

  return (
    <Formik key={countries.length} initialValues={initialValues} validationSchema={validationSchema} onSubmit={editLocation}>
      {({ isSubmitting, isValid, submitForm, setFieldValue }) => {
        return (
          <Modal
            title={mode === "editLocation" ? t("locations:edit_location") : t("locations:create_location")}
            buttons={
              <>
                {mode === "editLocation" ? (
                  <button className="btn btn-danger delete-location-button" onClick={() => showDeleteConfirm()} disabled={isSubmitting}>
                    {t("locations:delete_location")}
                  </button>
                ) : (
                  <div />
                )}
                <button className="btn btn-info" onClick={() => showAppModal(<ManageLocationsModal />)} disabled={isSubmitting}>
                  {t("common:close")}
                </button>
                <button className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={submitForm}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("common:save_changes")}
                </button>
              </>
            }>
            <form>
              <div>
                <FormFieldText name="name" label={t("locations:location_name")} placeholder={t("locations:location_name_placeholder")} />

                <div className="row">
                  <FormFieldText
                    displayError={true}
                    className="col-sm-6"
                    name="street1"
                    label={t("locations:address_line_1")}
                    placeholder=""
                  />
                  <FormFieldText
                    displayError={true}
                    className="col-sm-6"
                    name="street2"
                    label={t("locations:address_line_2")}
                    placeholder=""
                  />
                </div>

                <div id="city-state-zipcode-row" className="row">
                  <FormFieldText className="col-sm-6" name="city" label={t("locations:city")} placeholder="" />
                  <FormFieldText className="col-sm-3" name="state" label={t("locations:state")} placeholder="" />
                  <FormFieldText displayError={true} className="col-sm-3" name="zip" label={t("locations:zip_code")} placeholder="" />
                  <div id="country-select-mobile" className="col-sm-6 u-mobile-only">
                    <label className="input-label u-display-block">{t("locations:country")}</label>
                    <CountrySelect name="country" selectedCountryLabel="name" />
                  </div>
                </div>

                <div id="country-phone-row" className="row">
                  <div className="col-sm-6 u-mobile-hide">
                    <label className="input-label u-display-block">{t("locations:country")}</label>
                    <CountrySelect name="country" selectedCountryLabel="name" initialValue={"US"} />
                  </div>
                  <FormFieldText className="col-sm-6" name="phone" label={t("locations:phone")} placeholder="" type="telephone" />
                </div>

                <div id="allocated-users-container" className="form-group">
                  <label htmlFor="Users" className="input-label">
                    {t("locations:allocated_users")}
                  </label>
                  <input
                    type="text"
                    className="form-control input-with-icon"
                    placeholder={"\uf002    " + t("locations:search_users")}
                    value={searchUserString}
                    onChange={(e) => setSearchUserString(e.target.value)}
                  />
                  <ul className="select-group fix-height u-mobile-hide">
                    {filteredUsers.map((user) => (
                      <li key={user._id} className="select-group-item">
                        <FormFieldCheckbox
                          onChange={(e) => setFieldValue("Users", adjustUsersList(e.target.checked, user))}
                          id={`user-${user._id}`}
                          className="input-holder"
                          checked={isUserAllocated(user)}
                          name="Users"
                          label={`${user.first_name ?? ""} ${user.last_name ?? ""} ${user.email ?? ""}`}
                        />
                      </li>
                    ))}
                  </ul>
                  <ul className="select-group fix-height u-mobile-only">
                    {paginatedFilteredUsers.map((user) => (
                      <li key={user._id} className="select-group-item">
                        <FormFieldCheckbox
                          onChange={(e) => setFieldValue("Users", adjustUsersList(e.target.checked, user))}
                          id={`user-${user._id}`}
                          className="input-holder"
                          checked={isUserAllocated(user)}
                          name="Users"
                          label={`${user.first_name ?? ""} ${user.last_name ?? ""} ${user.email ?? ""}`}
                        />
                      </li>
                    ))}
                  </ul>
                  <div className="u-mobile-only">
                    <PaginationButtons
                      onPageChange={changeAllocatedUsersPagination}
                      pageCount={Math.ceil(filteredUsers.length / numberOfUsersPerPaginatedPage)}
                      size="small"
                    />
                  </div>
                </div>

                {isLocalWAM() ? (
                  <div className="row">
                    {/* Shared user only exist in local wam and also only available to admin user */}
                    <FormFieldText className="col-sm-6" name="sharedUser" label={t("locations:shared_username")} />
                  </div>
                ) : null}
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});

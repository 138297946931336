import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { alertConditionTransform } from "../../../Managers/AlertConditionService";
import { IAlert } from "../../../Managers/Types";
import { deleteAlert, updateAlert } from "../../../Managers/DeviceAlertService";
import { showSnackbar } from "../../../AppState";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-collapse";
import { List, ListItem, Row, SwitchInput } from "../../../Components";
import styled from "@emotion/styled";
import { Colors } from "../../../Theme";

interface IEditAlertsListProps {
  alerts: IAlert[];
  deviceId: number;
  onIsSaving?: (v: any) => void;
}

const StyledAlertRow = styled.div({
  backgroundColor: Colors.bodyBlue,

  i: {
    color: Colors.teal,
  },

  ".MuiListItem-root": {
    flexDirection: "column",
  },

  ".ReactCollapse--collapse": {
    width: "80%",
  },

  ".ReactCollapse--content": {
    width: "100%",

    ".collapse-row": {
      alignItems: "center",

      "> *": {
        width: "50%",
      },
    },
  },

  ".fa-chevron-down": {
    transition: "0.2s ease-out",
  },

  ".opened": {
    transform: "rotate(-180deg)",
  },

  ".alert-head-row": {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px 8px 8px 0",
  },

  ":not(:first-of-type)": {
    borderTop: `1px solid ${Colors.royalBlue}`,
  },
});

export const EditAlertsList: React.FC<IEditAlertsListProps> = observer(({ alerts, deviceId, onIsSaving = null }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [checkedAlerts, setCheckedAlerts] = useState(alerts.filter((a) => a.Sensor_alerts.is_active));
  const [openedAlertId, setOpenedAlertId] = useState<number>();

  const { t } = useTranslation("dashboard");

  useEffect(() => {
    setCheckedAlerts(alerts.filter((a) => a.Sensor_alerts.is_active));
  }, [alerts]);

  const toggleAlert = (alert: IAlert) => {
    const checkedAlertsCopy = [...checkedAlerts];
    onIsSaving?.(true);
    const alertIsActive = alert.Sensor_alerts.is_active;

    alert.Sensor_alerts.is_active = !alertIsActive;

    if (!alertIsActive) {
      checkedAlertsCopy.push(alert);
    } else {
      const alertToRemoveFromChecked = checkedAlerts.indexOf(alert);
      checkedAlertsCopy.splice(alertToRemoveFromChecked, 1);
    }

    setCheckedAlerts(checkedAlertsCopy);

    updateAlert([{ ...alert }])
      .then(() => {
        showSnackbar(
          t("dashboard:edit_alerts_list.change_status", {
            context: alertIsActive ? "off" : "on",
            name: alert.name,
          }),
          "success",
        );
        setIsSaving(false);
        onIsSaving?.(false);
      })
      .catch((e) => {
        console.error(e);
        showSnackbar(
          t("dashboard:edit_alerts_list.change_status_error", {
            context: alertIsActive ? "off" : "on",
            name: alert.name,
          }),
          "error",
        );
      });
  };

  const removeAlert = (alert: IAlert) => {
    console.log("Removing alert", alert);
    deleteAlert(alert.Sensor_alerts.SensorId, alert._id, deviceId)
      .then((r) => {
        const successMessage = t("dashboard:edit_alerts_list.delete_success");
        showSnackbar(successMessage, "success");
        console.log(successMessage, r);
      })
      .catch((e) => {
        console.log("Error deleting alert", e);
        showSnackbar(t("dashboard:edit_alerts_list.delete_error"), "error");
      });
  };

  return (
    <>
      {!alerts || !alerts.length ? (
        <p>{t("dashboard:edit_alerts_list.no_alert_available")}</p>
      ) : (
        <div>
          <List className="u-mobile-only">
            {alerts.map((alert) => (
              <StyledAlertRow key={alert._id}>
                <ListItem>
                  <div className="alert-head-row">
                    <button
                      className="btn btn-plain col-sm-2"
                      onClick={() => setOpenedAlertId(openedAlertId === alert._id ? undefined : alert._id)}>
                      <i className={classNames("fa fa-chevron-down", { opened: alert._id === openedAlertId })} />
                    </button>
                    <span style={{ flex: 1 }}>
                      {t("common:alert")} {alert.name}
                    </span>
                    {!alert.Sensor_alerts.is_active ? (
                      <button onClick={() => removeAlert(alert)} className="btn btn-plain col-sm-2">
                        <i className="fa fa-trash-o" />
                      </button>
                    ) : null}
                  </div>
                  <Collapse isOpened={alert._id === openedAlertId}>
                    <Row className="collapse-row">
                      <div className="input-label">{t("dashboard:edit_alerts_list.condition")}</div>
                      <div className="input-label">{t("common:on_off")}</div>
                    </Row>
                    <Row className="collapse-row">
                      <div>{alertConditionTransform(alert)}</div>
                      <SwitchInput onChange={() => toggleAlert(alert)} name="state" checked={alert.Sensor_alerts.is_active} />
                    </Row>
                  </Collapse>
                </ListItem>
              </StyledAlertRow>
            ))}
          </List>
          <div className="u-mobile-hide">
            <div className="select-group-item header">
              <div className="row u-full-width">
                <div className="col-xs-5">{t("dashboard:edit_alerts_list.alert_on_off")}</div>
                <div className="col-xs-7">{t("dashboard:edit_alerts_list.condition")}</div>
              </div>
            </div>

            <ul className="select-group">
              {alerts.map((alert) => (
                <li className="select-group-item" key={alert._id}>
                  <div className="row u-full-width">
                    <div className="col-xs-5 alert-name">
                      <label className={classNames("checkbox", { isSaving })} title={alert.name}>
                        <input
                          checked={checkedAlerts.some((a) => a._id === alert._id)}
                          type="checkbox"
                          className="input-checkbox"
                          id={"" + alert._id}
                          onChange={() => toggleAlert(alert)}
                          disabled={isSaving}
                        />
                        <span />
                        {alert.name}
                      </label>
                    </div>
                    <div className="col-xs-7 alert-condition">{alertConditionTransform(alert)}</div>
                  </div>
                  {!alert.Sensor_alerts.is_active && (
                    <button className="btn btn-plain" onClick={() => removeAlert(alert)}>
                      <i className="fa fa-times-circle" />
                      <span className="sr-only">{t("dashboard:edit_alerts_list.remove_alert")}</span>
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
});

import bb, { bubble, Chart, line, zoom } from "billboard.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "billboard.js/dist/billboard.css";
import "./TimeSeriesLineChart.scss";
import { ErrorBoundary } from "./ErrorBoundary";
import { useTranslation } from "react-i18next";
import { Colors } from "../Theme";
import classNames from "classnames";
import { useScreenMode } from "./ViewportProvider";
import { WindowSize } from "../Enums";
import { CircularProgress } from "@mui/material";
import { getTimeFormatValueForUser } from "../Enums/TimeFormat";
import moment from "moment";
import { getUserDateFormat } from "../AppState";
import { sleep } from "../Managers";
import * as d3 from "d3";

// NOTE: This is the "new" component. For reference to old functionality see OldLineChart.tsx
export interface ISelectedRange {
  startTime: Date;
  endTime: Date;
}

export interface ISetPoint extends ILineChartValue {
  _id: number;
  elapsed?: string;
  targetTriggerId?: number;
}

export interface ILineChartValue {
  x: Date;
  y: number | string | null;
  unit: string;
  triggeredTarget: number | null;
  color?: string;
}

export interface ILineChartProps {
  className?: string;
  data: ILineChartValue[];
  setPoints?: ISetPoint[];
  valueName?: string;
  setFilteredDataHook?: (data: ILineChartValue[]) => void;
  onSetPointClick?: (data: ISetPoint) => void;
  onExport?: (imgData: any) => void;
  bindToSuffix?: string;
  onRendered?: () => void;
  height?: number;
  width?: number;
  title?: string;
  onZoomDomainChange?: (domain: string[]) => void;
  defaultOriginalZoomDomain?: string[];
  loading?: boolean;
  zoomEnabled?: boolean;
  holderClassName?: string;
  selectedAreas?: ISelectedRange[];
}

export const TimeSeriesLineChart: React.FC<ILineChartProps> = ({
  data,
  setPoints = [],
  className,
  valueName = "Value",
  setFilteredDataHook = null,
  onSetPointClick = null,
  onExport = null,
  bindToSuffix = "",
  onRendered,
  height,
  width,
  title,
  onZoomDomainChange,
  defaultOriginalZoomDomain,
  loading,
  zoomEnabled = true,
  holderClassName,
  selectedAreas,
}) => {
  const originalZoomDomain = useMemo(
    () =>
      defaultOriginalZoomDomain ??
      (data.length > 0 && data[0].x ? [data[0].x.toISOString(), data[data.length - 1].x.toISOString()] : ["", ""]),
    [data],
  );

  const [_chartComponent, setChartComponent] = useState<Chart | null>(null);
  const [zoomDomain, setZoomDomain] = useState<string[]>(originalZoomDomain);
  const [dataSeriesGroups, setDataSeriesGroups] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<ILineChartValue[]>(data);
  const [showResetZoom, setShowResetZoom] = useState(false);
  const [rendered, setRendered] = useState(false);
  const [ready, setReady] = useState(false);

  const { t } = useTranslation("common");
  const mode = useScreenMode();

  const dateFormat = getUserDateFormat();
  const timeFormat = getTimeFormatValueForUser();

  useEffect(() => {
    if (onExport && chart && rendered) {
      chart.export({ mimeType: "image/png", preserveFontStyle: true }, (item) => onExport(item));
    }
  }, [onExport, rendered, data]);

  const isBoolean = data?.[0]?.unit.includes("/");

  setTimeout(() => {
    try {
      if (chart) {
        chart.resize({ width, height });
      }
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => filterValues(zoomDomain), [data]);

  const handleResetZoom = () => {
    setFilteredData(data);
    setZoomDomain(originalZoomDomain);
    setShowResetZoom(false);
    onZoomDomainChange?.(originalZoomDomain);
  };

  const columns = useMemo(() => {
    const x1 = filteredData.map((entry) => entry.x);
    const hasMoreThanOneSeries = filteredData.some((entry) => entry.color);
    const columnsData = [];
    const seriesNames: React.SetStateAction<string[]> = [];

    columnsData.push(["x1", ...x1]);

    if (hasMoreThanOneSeries) {
      const groupedData = filteredData.reduce<{ [key: string]: ILineChartValue[] }>((acc, entry) => {
        const color = entry.color || "default";
        if (!acc[color]) {
          acc[color] = [];
        }
        acc[color].push(entry);
        return acc;
      }, {});

      Object.entries(groupedData).forEach(([color, dataGroup]) => {
        const values = dataGroup.map((entry) => {
          if (isBoolean && typeof entry.y === "string") {
            return entry.unit
              .split("/")
              .map((item) => item.toUpperCase())
              .indexOf(entry.y.toUpperCase());
          }
          return entry.y;
        });

        const columnNameX = `x_${color}`;
        const columnNameY = `values_${color}`;

        seriesNames.push(color);

        const xValuesForColor = dataGroup.map((entry) => entry.x);

        columnsData.push([columnNameX, ...xValuesForColor]);
        columnsData.push([columnNameY, ...values]);
      });
    } else {
      const values = filteredData.map((entry) => {
        if (isBoolean && typeof entry.y === "string") {
          return entry.unit
            .split("/")
            .map((item) => item.toUpperCase())
            .indexOf(entry.y.toUpperCase());
        }

        return entry.y;
      });
      columnsData.push(["values", ...values]);
    }

    const x2 = setPoints.map((entry) => entry.x);
    const mappedSetPoints = setPoints.map((entry) => Number(entry?.y));
    const setPointLine = setPoints.map((entry) => Number(entry?.y));

    columnsData.push(["x2", ...x2]);
    columnsData.push(["set_points", ...mappedSetPoints]);
    columnsData.push(["set_point_line", ...setPointLine]);

    if (hasMoreThanOneSeries) {
      setDataSeriesGroups(seriesNames);
    }

    return columnsData;
  }, [data, filteredData, setPoints]);

  useEffect(() => {
    // console.warn("document.getElementById(`line-chart${bindToSuffix}`)", document.getElementById(`line-chart${bindToSuffix}`));
    if (!ready) {
      setReady(!!document.getElementById(`line-chart${bindToSuffix}`));
    }
  }, [document.getElementById(`line-chart${bindToSuffix}`)]);

  const chart = document.getElementById(`line-chart${bindToSuffix}`)
    ? (() => {
        try {
          return bb.generate({
            title: title ? { text: title } : undefined,
            bindto: `#line-chart${bindToSuffix}`,
            transition: {
              duration: 0,
            },
            onrendered: async () => {
              if (!rendered) {
                setRendered(true);
              }
              if (selectedAreas) {
                await sleep(0); // this way we make sure chart will be already rendered
                const chartEl = document.getElementById(`line-chart${bindToSuffix}`);
                if (!chart || !chartEl) {
                  return;
                }

                const chartInternal = (chart as any).internal;
                const bbChartG = d3.select(chartEl).select(".bb-chart");

                const xScale = chartInternal.scale.x; // Get Billboard.js X scale

                bbChartG.selectAll(".brush").remove(); // Remove old brush if any

                selectedAreas.forEach((selectedRange) => {
                  const brush = d3.brushX().on("start brush end", null);

                  const brushG = bbChartG.append("g").attr("class", "brush").call(brush);

                  const x0 = selectedRange.startTime,
                    x1 = selectedRange.endTime;

                  const pixelSelection: [number, number] = [xScale(x0), xScale(x1)];

                  brush.move(brushG, pixelSelection);

                  brushG.on(".brush", null);
                  brushG.selectAll(".handle").style("display", "none");
                  brushG.selectAll(".overlay").style("pointer-events", "none");
                  brushG.selectAll(".selection").style("pointer-events", "none");
                });
              }
            },
            onafterinit() {
              // console.warn("AFTER INIT RENDERED GRAPH");
              onRendered?.();
            },
            data: {
              onclick(this, dataItem, _element) {
                if (onSetPointClick != null) {
                  const { x: date, value } = dataItem;
                  const originalIndex = data.findIndex((value) => value.x.getTime() === new Date(date).getTime());
                  const id = originalIndex ?? 0;
                  if (id > 0) {
                    const lineChartValue: ILineChartValue = data[id];
                    const { unit, x, y } = lineChartValue;
                    const setPoint: ISetPoint = { _id: id, y, triggeredTarget: value, unit, x };

                    onSetPointClick?.(setPoint);
                  }
                }
              },
              xs: {
                values: "x1",
                set_points: "x2",
                set_point_line: "x2",
                ...Object.fromEntries(
                  (dataSeriesGroups.length > 0 ? dataSeriesGroups : []).map((color) => [`values_${color}`, `x_${color}`]),
                ),
              },
              type: line(),
              types: {
                set_points: bubble(),
                set_point_line: line(),
              },
              colors: {
                values: "#55cdcd",
                set_points: Colors.warning,
                set_point_line: Colors.lightGray,
                ...Object.fromEntries((dataSeriesGroups.length > 0 ? dataSeriesGroups : []).map((color) => [`values_${color}`, color])),
              },
              columns,
            },
            // @see https://naver.github.io/billboard.js/release/latest/doc/Options.html#.line
            line: {
              // This is the default but I force-set it here in case that ever changes. We do not want 'null' entries "connected".
              connectNull: false,
              classes: ["values", "set_points", "values2", "values3", "values4"],
              point: ["values", "set_points", "set_point_line"],
            },
            point: {
              r: 1,
            },
            bubble: {
              maxR: 8,
            },
            zoom: {
              enabled: zoom() && zoomEnabled,
              type: mode === WindowSize.DESKTOP ? "drag" : "wheel",
              onzoomend(this, domain) {
                setZoomDomain(domain);
                filterValues(domain);
              },
              resetButton: false,
            },
            legend: {
              show: false,
            },
            tooltip: {
              format: {
                title: (entry: Date) => moment(entry).format(`${dateFormat} ${timeFormat}`),
                name: (name: string) => (name === "values" ? valueName : t("common:setpoint")),
                value: (entry) => {
                  let value = `${entry}`;
                  if (isBoolean) {
                    const unitValues = data[0].unit.split("/");
                    return t(`common:select_options.${unitValues[entry]?.toLowerCase()}`).toUpperCase();
                  }
                  return `${value} ${data?.[0]?.unit || ""}`;
                },
              },
              grouped: false,
              show: true,
            },
            axis: {
              x: {
                tick: {
                  fit: false,
                  count: 5,
                },
                type: "timeseries",
              },
              y: {
                tick: {
                  format: (x: number) => {
                    if (isBoolean) {
                      const unitValues = data[0]?.unit.split("/");
                      return t(`common:select_options.${unitValues[x]?.toLowerCase()}`).toUpperCase();
                    }
                    return x.toString();
                  },
                },
              },
            },
            grid: {
              y: {
                show: true,
              },
            },
            boost: {
              useCssRule: true,
            },
            interaction: {
              inputType: {
                mouse: true,
                touch: false,
              },
            },
          });
        } catch (error) {
          console.error("Error generating chart:", error);
          return null;
        }
      })()
    : null;

  if (isBoolean) {
    // get all y values, filter to see if there are ON values, see if every value is null
    // if it's null, that means there aren't any ON values in this result set
    const allYValues = data.map((x) => x.y);
    const allContactTheSame = allYValues.every((v) => v === allYValues[0] || v === null || v === undefined);

    const count = allContactTheSame ? 1 : 3;
    const values = allContactTheSame ? [0] : [0, 1];

    chart?.config("axis.y.tick.count", count, true);
    chart?.config("axis.y.tick.values", values, true);
  }
  const filterValues = useCallback(
    (zoomDomain: string[]) => {
      const filtered = data.filter((d) => d.x >= new Date(zoomDomain[0]) && d.x <= new Date(zoomDomain[1]));
      setFilteredData(filtered);
      if (setFilteredDataHook !== null) {
        setFilteredDataHook(filtered);
      }
    },
    [data, setFilteredDataHook],
  );

  useEffect(() => setChartComponent(chart), [ready]);

  // when zoom changes
  useEffect(() => {
    if (zoomDomain !== originalZoomDomain) {
      setShowResetZoom(true);
      filterValues(zoomDomain);
      chart?.zoom(zoomDomain);
      onZoomDomainChange?.(zoomDomain);
    }

    if (zoomDomain === originalZoomDomain) {
      setShowResetZoom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomDomain]);

  return (
    <ErrorBoundary>
      <div className={classNames("line-chart-holder", holderClassName)}>
        <div
          className="bb-button"
          id="btn-reset-zoom"
          onClick={() => handleResetZoom()}
          style={{ visibility: showResetZoom ? "inherit" : "hidden" }}>
          <span className="bb-zoom-reset">{t("common:reset_zoom")}</span>
        </div>
        <div id={`line-chart${bindToSuffix}`} className={className}></div>
        {loading ? <CircularProgress className="chart-load-indicator" /> : null}
      </div>
    </ErrorBoundary>
  );
};

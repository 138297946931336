import * as yup from "yup";
import { Formik } from "formik";
import React from "react";
import { observer } from "mobx-react-lite";
import { FormFieldSelect, FormFieldText } from "../../../Components";
import { AppState, showAppModal, showSnackbar } from "../../../AppState";
import { ISensor, IWatchlist } from "../../../Managers/Types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../Components/Modal";
import { addSensorToWatchlists, createWatchlist, editWatchlist } from "../../../Managers/WatchlistsService";
import "./AddEditWatchlistModal.scss";
import { AddToWatchlistModal } from "./AddToWatchlistModal";
import { useSensorTypes } from "../../../Managers/AlertService";

interface IEditWatchlistProps {
  watchlist?: IWatchlist | null;
  mode: "new" | "edit";
  sensor?: ISensor | null;
}

const validationSchema = yup.object({
  name: yup.string().required().min(3).max(255),
  unit: yup.string().required(),
  SensorTypeId: yup.number().nullable().required("SensorTypeId is required"),
  UserId: yup.string().required(),
});

export const EditWatchlistModal: React.FC<IEditWatchlistProps> = observer(({ watchlist, sensor, mode }) => {
  const { t } = useTranslation(["dashboard", "common"]);
  const { isFetching, data: sensorTypes } = useSensorTypes();

  if (isFetching || !sensorTypes || sensorTypes.length === 0) {
    return <></>;
  }

  const sensorTypesOptions = sensorTypes.map((option) => ({ value: option._id, label: option.name }));

  const defaultSensorOption = sensorTypesOptions.find((type) => type.label === "Temperature") || sensorTypesOptions[0];

  const defaultSensorType = defaultSensorOption && sensorTypes.find((sensorType) => sensorType._id === defaultSensorOption.value);

  const defaultUnit = defaultSensorType?.units?.[0];

  const initialValues = {
    name: watchlist?.name || "",
    UserId: AppState.user?._id || 0,
    unit: watchlist?.unit || defaultUnit || "",
    SensorTypeId: watchlist?.SensorTypeId || defaultSensorOption?.value || undefined,
  } as IWatchlist;

  const handleAddWatchlist = async (values: IWatchlist) => {
    const sensorType = sensorTypes.find((sensorType) => sensorType._id === values.SensorTypeId);

    if (!sensorType || (sensorType.units ?? []).every((unit) => unit !== values.unit)) {
      return;
    }

    if (!values) {
      return;
    }

    if (watchlist == null) {
      const watchlist: IWatchlist = {
        ...values,
        UserId: AppState.user?._id,
      };

      try {
        const createdWatchlist = await createWatchlist(watchlist);
        showSnackbar(t("dashboard:edit_watchlist_modal.create_success"), "success");
        if (sensor && createdWatchlist && typeof createdWatchlist.SensorTypeId === "number") {
          try {
            if (createdWatchlist.SensorTypeId === sensor.SensorTypeId) {
              await addSensorToWatchlists(sensor, [createdWatchlist]);
              showSnackbar(t("dashboard:edit_watchlist_modal.add_watchlist_sensor_success"), "success");
            } else {
              showSnackbar(t("dashboard:edit_watchlist_modal.add_watchlist_sensor_type_error"), "error");
            }
          } catch (errorSensorAdd) {
            console.error("Error while adding sensor to watchlist: ", errorSensorAdd);
          }
        }
      } catch (e) {
        showSnackbar(t("dashboard:edit_watchlist_modal.create_error"), "error");
        console.error("Create error", e);
      } finally {
        showAppModal(null);
      }
    } else {
      values._id = watchlist._id;
      try {
        await editWatchlist(values);
        showSnackbar(t("dashboard:edit_watchlist_modal.edit_success"), "success");
      } catch (error) {
        showSnackbar(t("dashboard:edit_watchlist_modal.edit_error"), "error");
        console.error("Update error", error);
      } finally {
        showAppModal(null);
      }
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddWatchlist}>
      {({ isSubmitting, submitForm, values, setFieldValue }) => {
        const selectedSensorType = values.SensorTypeId ? sensorTypes.find((sensorType) => sensorType._id === values.SensorTypeId) : null;
        const setUnitLabel = (label: string) => {
          switch (label) {
            case "celcius":
              return "°C";
            case "fahrenheit":
              return "°F";
            case "RH":
              return "%RH";
            default:
              return label;
          }
        };
        const unitOptions = selectedSensorType?.units.map((option) => ({ value: option, label: setUnitLabel(option) })) ?? [];

        return (
          <Modal
            className="modal-sm watchlist-modal"
            title={t(`dashboard:edit_watchlist_modal.title_${mode}`)}
            buttons={
              <>
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={isSubmitting}
                  onClick={() => (sensor ? showAppModal(<AddToWatchlistModal sensor={sensor} />) : showAppModal(null))}>
                  {t("common:cancel")}
                </button>

                <button type="button" className="btn btn-primary" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("common:save")}
                </button>
              </>
            }>
            <>
              <FormFieldText
                name="name"
                label={t("dashboard:edit_watchlist_modal.name")}
                placeholder={t("dashboard:edit_watchlist_modal.name_placeholder")}
                required
              />

              <div className="row">
                <div className="col-xs-6">
                  <FormFieldSelect
                    style={{ margin: 0 }}
                    className="input-filter-holder"
                    options={sensorTypesOptions || []}
                    name="SensorTypeId"
                    onChange={(value) => {
                      const selectedSensorType = sensorTypes.find((sensorType) => sensorType._id === value);
                      const newUnit = selectedSensorType?.units?.[0];
                      setFieldValue("unit", newUnit).then();
                    }}
                    label={t("dashboard:edit_watchlist_modal.sensor_type")}
                  />
                </div>

                <div className="col-xs-6">
                  <FormFieldSelect
                    style={{ margin: 0 }}
                    className="input-filter-holder"
                    options={unitOptions}
                    name="unit"
                    label={t("dashboard:edit_watchlist_modal.unit")}
                  />
                </div>
              </div>
            </>
          </Modal>
        );
      }}
    </Formik>
  );
});
